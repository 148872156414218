$(function () {
	$("#cateringCTAButton").on("click", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "startcateringorderPDP"
		});
	});

	$("#downloadCTAButton").on("click", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "downloadapptoorderPDP"
		});
	});

	$("#showFullNutrition").on("click", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "showinformationPDP"
		});
	});
});
