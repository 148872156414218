$(function () {
	$("body").on("click", ".close-overlay", function (e) {
		e.preventDefault();
		$.magnificPopup.close();
	});

	//Confirmation Popup
	$("body").on("click", 'a[class^="ewk-"], .extwarn', function (e) {
		e.preventDefault();
		var containerId = "defaultsiteoverlay";
		var keyValue = "";
		var classes = $(this).attr("class").split(" ");
		for (var i = 0; i < classes.length; i++) {
			var matches = /^ewk\-(.+)/.exec(classes[i]);
			if (matches != null) {
				keyValue = matches[1];
			}
		}

		$.ajax({
			url: "/CFACanada/PageContent/ConfirmationOverlay",
			type: "GET",
			data: {
				OverlayKey: keyValue,
				UrlRedirectValue: $(this).prop("href")
			}
		}).done(function (partialViewResult) {
			$("#" + containerId).html(partialViewResult);

			$.magnificPopup.open({
				items: {
					src: "#" + containerId,
					type: "inline"
				}
			});
		});
	});

	$("body").on("click", ".extwarnjobopportunity", function (e) {
		e.preventDefault();
		var containerId = "#jobopportunitiesoverlay";
		var url = $(this).prop("href");

		$(containerId).find("a.continue-overlay").prop("href", url);

		$.magnificPopup.open({
			items: {
				src: containerId,
				type: "inline"
			}
		});
	});
});
