$(function () {
	if ($(".story-landing")[0]) {
		$(".main-nav>ul>li:nth-child(2) .subnav").slideDown("slow");
	}

	$(".main-nav").on("mouseleave", function () {
		if (!$(".story-landing, .about").length) {
			if ($(".main-nav .subnav:visible")) {
				$(".main-nav .subnav").delay(200).fadeOut();
			}
		}
	});

	var windowHeight = $(window).height();
	var theHeight = windowHeight - 66;
	var theMenu = $("#menu");
	$(".main-nav #navitem-stories .subnav").slideDown("slow");
	$(".stories").css("height", theHeight);
	var storiesHeight = $(".stories").height();
	var storiesScroll = storiesHeight - 70;
	$(".stories-feature").hide();
	$(
		".main-nav #navitem-menu .subnav, .main-nav #navitem-about .subnav"
	).mouseleave(function () {
		$(
			".main-nav #navitem-menu .subnav, .main-nav #navitem-about .subnav"
		).fadeOut();
		$(".main-nav #navitem-stories .subnav").fadeIn();
	});
	$(".main-nav #navitem-stories .subnav").on("mouseenter", function () {
		$(".stories-feature").show();
	});
	$(window).scroll(function () {
		if (
			$(this).scrollTop() >
			storiesHeight + $("#scPageExtendersForm").height()
		) {
			$(".header-frame").addClass("lock");
			$(".nav-toggle").addClass("active");
			$(".navitem-hoverable a").addClass("off-screen");
			$(".main-nav #navitem-about .subnav").slideUp("slow");
			$(".main-nav #navitem-menu .subnav").slideUp("slow");
			$(".main-nav #navitem-stories .subnav").slideUp("slow");
		} else {
			$(".header-frame").removeClass("lock");
			$(".nav-toggle").removeClass("active");
			$(".navitem-hoverable a").removeClass("off-screen");
			$(".main-nav #navitem-stories .subnav").slideDown("slow");
			$(".stories-feature").hide();
		}
	});
});
